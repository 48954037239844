/* Estilos básicos */
.app__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: var(--color-black);
  }
  
  .app__wrapper_info {
    flex: 1;
    padding-right: 2rem;
  }
  
  .app__wrapper_img {
    flex: 1;
    text-align: center;
  }
  
  .app__wrapper-content {
    margin-bottom: 2rem;
  }
  
  .custom__button {
    background-color: #3C7547;;
    color: #fff;
    border: none;
    padding: 0.75rem 2rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .custom__button:hover {
    background-color: var(--color-golden-dark);
  }
  
  /* Estilos para imágenes */
  .app__wrapper_img img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Media Queries para pantallas más pequeñas */
  @media screen and (max-width: 768px) {
    .app__wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .app__wrapper_info {
      padding-right: 0;
    }
  
    .app__wrapper_img {
      margin-top: 2rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .headtext__cormorant {
      font-size: 1.75rem;
    }
  
    .p__opensans {
      font-size: 0.875rem;
    }
  
    .custom__button {
      padding: 0.5rem 1.5rem;
      font-size: 0.875rem;
    }
  }
  