.app__footer {
    width: 100%;
    background: var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    color: #3c7547;
  }
  
  .app__footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin-top: 3rem;
    gap: 2rem;
  }

  .app__footer-links_logo {
    flex: 1;
    min-width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido horizontalmente */
  }
  
  .app__footer-links_logo img:first-child {
    width: 150px;
    margin-bottom: 1rem;
  }
  
  
  .app__footer-links_contact,
  .app__footer-links_logo,
  .app__footer-links_work {
    flex: 1;
    min-width: 250px;
    text-align: center;
  }
  
  .app__footer-links_logo img:first-child {
    width: 150px;
    margin-bottom: 1rem;
  }
  
  .app__footer-links_icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .app__footer-links_icons a {
    color: var(--color-white);
    margin: 0 0.5rem;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .app__footer-links_icons a:hover {
    color: var(--color-golden);
  }
  
  .app__footer-headtext {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .footer__copyright {
    margin-top: 2rem;
    text-align: center;
    font-size: 0.875rem;
  }
  
  /* Ajuste para pantallas grandes */
  @media screen and (min-width: 2000px) {
    .app__footer-headtext {
      font-size: 36px;
    }
  }
  
  /* Ajuste para pantallas medianas */
  @media screen and (max-width: 1150px) {
    .app__footer-links {
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* Ajuste para pantallas pequeñas */
  @media screen and (max-width: 650px) {
    .app__footer {
      padding: 2rem 1rem;
    }
  
    .app__footer-headtext {
      font-size: 20px;
    }
  
    .app__footer-links_logo img:first-child {
      width: 120px;
    }
  }
  
  img{
    
  }